import TimelineDatabase from "#constants/TimelineDatabase";
import * as React from 'react';
import TimelineStep from "./timeline-step";

const Timeline = () => {
    return (
        <ol className="ml-3 xs:ml-0 relative border-l border-gray-200 dark:border-gray-700 mt-16 md:mt-24 prose prose-base w-full !mx-auto">
            {TimelineDatabase && TimelineDatabase.map((timelineStep, index) => (
                <TimelineStep timelineStep={timelineStep} key={index} />
            ))}
        </ol>
    );
}

export default Timeline;