const TimelineDatabase = [
    {
        title: "First contact",
        description: "I don't even know which year was it. The only thing I remember was the Easter morning and our dad giving us the Commodore64 machine. The keyboard, the cassette player and a small screwdriwer. Say hello to a kid with a new spark and a whole lot of ideas.",
    },
    {
        title: "DOS ether",
        description: "Blackness. But things started to make more sense. Interaction was easier.",
    },
    {
        title: "3.11",
        description: "Booting up a graphical user interface. The mouse. I remember writing stories about my day in Microsoft Word and just exiting without saving them.",
    },
    {
        title: "Hello World",
        description: "Opening up Netscape default AOL pages opens up. I remember not knowing what I was looking at.",
    },
    {
        title: "Multimedia",
        description: "With a curiosity, computers were endless learing machines. Adobe Photoshop and the Macromedia Flash and Dreamweaver were my goto programs when I booted up the PC.",
    },
    {
        title: "The gamechanger",
        description: "It was the end of the 5th grade. My school announced that it will be starting a informatics class as an extracuricular subject, and that it would involve programming. Guess who boarded that flight.",
    },
    {
        title: "Break and repair",
        description: "The last two years of primary school I learned programming in Q basic, and taught myself to break computers, repair them.",
    },
    {
        title: "Don't consume. Create",
        description: "While learing programming in Pascal, the high school times which I remember were more focused on self learing HTML, CSS and starting to boost my Flash skillset which evolved into 3D websites and full length cartoons.",
    },
    {
        title: "Math addiction",
        description: "I entered the Faculty of Science, department of mathematics, where I spent years working around several technologies and concepts like several operating systems, programming languages, databases, algorithms, data structures,...",
    },
    {
        title: "A natural ocurrence",
        description: "As my pre-teen years were filled by graphic designer software, the natural path which my mind took is to start creating richer user interfaces. Thus, my love for JavaScript was born.",
    },
    {
        title: "Something is on/off",
        description: "Now I had the joy of building software, my web frontend skillset grew. But I missed matter. I wanted physical buttons. Who would have known, but around that time, Arduino was born and we became best friends. Nothing can replace that sweet feeling of burning my first board.",
    },
    {
        title: "My path",
        description: "Designing and developing the GUI was fun, but it always lacked one thing for me. The brain. My college mentor mentioned a new technology which was written in a language which I already knew, and asked me if I wanted to write my masters thesis in it. As I love challenges, it was time to introduce myself to NodeJS. Little did I know what I was getting myself into. This was the point where I knew that I wanted to professionally grow as a backend engineer.",
    },
    {
        title: "Scaling",
        description: "Since I spent much of my lifetime around computers, programming languages and challenges, the next couple of years I focused on learing theory around scaling my projects and writing maintainable code and putting it into practice.",
    },
    {
        title: "A machine can do it",
        description: "I was always disgusted by manual work with something which can clearly be done by a machine. While buying components to automate my home, I started using my knowledge of technology to develop automated systems which integrate into my daily routine. And for the sake of completeness, writing began.",
    },
    {
        title: "Functional approach",
        description: "Currently I'm working on a microservice-based product at a great scale, fully written in a functional programming language, called Elixir.",
    },
    {
        title: "...",
        description: " As a kid I wondered if my love for technology was ever going to fade away or, at least, loosen a bit. As I can see, the climb is steep and I cannot wait for new and exciting challenges which wait for me ahead.",
    }
];

export default TimelineDatabase;