import { CalendarIcon } from '@heroicons/react/24/solid';
import * as React from 'react';

const TimelineStep = ({ timelineStep }) => {
    return (
        <li className="mb-10 ml-6" key={timelineStep.title}>
            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-stone-200 rounded-full ring-8 ring-transparent dark:ring-gray-900 dark:bg-stone-600">
                <CalendarIcon className="w-3 h-3 text-stone-600 dark:text-stone-400"></CalendarIcon>
            </span>
            <h3 className="flex items-center mb-1 font-semibold text-gray-900 dark:text-white">
                {timelineStep.title}
            </h3>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                {timelineStep.date}
            </time>
            <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                {timelineStep.description}
            </p>
        </li >
    );
}

export default TimelineStep;