import { Link } from "gatsby";
import * as React from 'react';

const Biography = () => {
    return (
        <section className="mt-10 md:mt-24">
            <div className="relative flex">
                <div className="min-h-[70vh] lg:w-1/3"></div>
                <div className="hidden w-2/3 min-h-[70vh] lg:block rounded-lg bg-gradient-to-b from-slate-300 to-[#EEEEF8] dark:from-gray-800 dark:to-[#171717]"></div>

                <div
                    className="flex flex-col justify-center w-full mx-auto lg:absolute lg:inset-x-0">
                    <h1 className="text-3xl lg:pl-72 lg:pt-16 lg:pb-8 text-center lg:text-left font-semibold text-gray-800 capitalize lg:text-2xl xl:text-2xl dark:text-white">
                        Hello, stranger <span className="inline-block animate-wiggle" role="img" aria-label="hand-wave">👋</span>
                    </h1>

                    <div className="mt-10 lg:mt-10 lg:flex text-center lg:text-left lg:items-center">
                        <img className="object-cover object-center w-full lg:w-[32rem] rounded-none lg:h-min -scale-x-100 dark:saturate-[0.7] dark:contrast-[1.2] " src="/preview.png" alt="" />

                        <div className="mt-6 md:mt-16 lg:px-10 lg:mt-0">
                            <h1 className="text-sm lg:w-72">
                                My name is <span className="font-bold dark:font-normal dark:text-white">Ivan Lesar</span>
                            </h1>
                            <p className="max-w-lg mt-16 lg:mt-6 text-sm mx-auto">
                                I'm a backend software engineer with a masters degree in mathematics and computing from the Faculty of Science in Zagreb, Croatia.
                                Currently I'm working as a backend engineer in <a href="https://superology.com/" target="_blank" rel="noopener noreferrer" className="underline">Superology</a>.
                            </p>
                            <p className="max-w-lg mt-6 text-sm mx-auto">
                                My free time is spent traveling with my wife and daughter, learing, woodworking, soldering, making our home smarter, and generally spending time in my workshop.
                            </p>
                            <p className="max-w-lg mt-6 text-sm mx-auto">
                                When I'm not tinkering with all kinds of materials, I'm reading about the human brain and its mysterious ways.
                            </p>
                            <p className="max-w-lg mt-8 text-sm mx-auto">
                                <Link to="/contact" className="underline">Wanna ask me something?</Link>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Biography;