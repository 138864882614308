import Biography from "#components/about/biography"
import Timeline from "#components/about/timeline"
import Container from "#components/global/container"
import Layout from "#components/global/layout"
import Seo from "#components/global/seo"
import { incrementCounter } from "#utils/firebase"
import { useLocation } from "@gatsbyjs/reach-router"
import { ArrowLongDownIcon } from "@heroicons/react/24/solid"
import { graphql, Link } from "gatsby"
import * as React from "react"
import { useEffect } from "react"

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const location = useLocation()

  useEffect(() => {
    incrementCounter(location, `pages/about/views`)
  }, [])

  return (
    <Layout siteTitle={{ siteTitle }}>
      <Seo
        title="About"
        description="The description of a tinker"
        tags="about"
      />
      <Container className="!pt-0 !md:pt-24">
        <Biography />
        <div className="w-full flex justify-center place-content-center mt-16 ">
          <ArrowLongDownIcon className="w-8 h-8 animate-bounce hidden md:block"></ArrowLongDownIcon>
        </div>

        <h1 className="mt-32 md:mt-48 md:mb-3 text-2xl font-semibold tracking-tight text-center lg:leading-snug text-brand-primary lg:text-2xl dark:text-white">
          My memories
        </h1>
        <Timeline />
        <div className="text-center my-48 md:my-64">
          <p className="text-sm">
            Now that you know a bit more about me, I hope the{" "}
            <Link to="/" className="underline text-blue-500">
              blog
            </Link>{" "}
            makes a bit more sense.
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
